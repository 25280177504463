<template>
  <div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadData"></head-component>
    <shipblu-table
     :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Full Name')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Address Line 1')}}</shipblu-th>
        <shipblu-th>{{$t('Address Line 2')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Assignd Agent')}}</shipblu-th>
        <shipblu-th>{{$t('Confirm')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :unHover="true" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].tracking_number" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <template>
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
              </div>
            </template>
          </shipblu-td>

          <shipblu-td  :data="data[indextr].addressLine">
            <div class="flex items-center justify-between">
              {{ data[indextr].customer.address.line_1 }}
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].addressLine">
            <div class="flex items-center justify-between">
              {{ data[indextr].customer.address.line_2 }}
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].Zone">
            <div class="flex w-full">
              {{ data[indextr].customer.address.zone.name }}
            </div>
          </shipblu-td>

          <shipblu-td>
            <div class="flex justify-between select-gov h-full gap-2" @click="loadAgents(data[indextr].customer.address.zone.id)">
              <v-select name="slot" :placeholder="$t('Assigned Agent')" v-model="data[indextr].agent" :options="caAgents" label="fullName"/>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].confirm">
            <vs-button v-if="$route.query && $route.query.filter && $route.query.filter === 'pending'" @click="sortOrder(data[indextr])" class="ml-1 font-semibold bg-confirm">
              <p>{{$t('Confirm')}}</p>
            </vs-button>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import vSelect from 'vue-select'
import HeadComponent from '../merchant/components/Header.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      filters: '',
      statusFilters: [
        {
          name: 'Pending',
          value: 'pending'
        },
        {
          name: 'Completed',
          value: 'completed'
        }
      ],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      tableLoader: false,
      filter: '',
      searchVal: '',
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchInProgress: false,
      searchedValue: ' ',
      selectedFlag: false,
      caAgents: []
    }
  },
  watch: {
    currentPage () {
      this.selected = []
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadData()
    },
    '$route.params.warehouseID' () {
      this.selected = []
      this.searchVal = ''
      this.currentPage = 1
      this.filters = 'pending'
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadData()
    },
    selected (val) {
      if (val.length !== 0 && val.length > 1 && val.filter(item => item.status === 'completed').length === 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    },
    '$route.query.filter' () {
      this.filters = []
      this.selected = []
      this.currentPage = 1
    } 
  },
  methods: {
    changeFilter () {
      this.filters = this.filterValue
      this.searchVal = ''
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadData()
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadData()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search && search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadData()
    },
    loadData () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `api/v1/warehousing/rezoning/shared-orders/?status=${this.filters}&warehouse_code=${this.$route.params.warehouseID}&${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    sortOrder (order) {
      let counter = 0
      order.packages.forEach(item => {
        sendRequest(true, false, this, `api/v1/warehousing/${this.$route.params.type}/packages/${item.package.id}/sort/?checkout_agent=${order.agent.id}`, 'get', null, true,
          () => {
            counter = counter + 1
            if (order.packages.length === counter) {
              this.$router.push({
                query: {
                  filter: 'completed'
                }
              }).catch(() => {})
              this.loadData()
            }
          })
      })
    },
    loadAgents (zoneID) {
      sendRequest(true, false, this, `api/v1/users/customer-agents/?zone=${zoneID}`, 'get', null, true,
        (response) => {
          this.selected = []
          this.caAgents = response.data.results
          this.caAgents.forEach(item => {
            item.fullName = `${item.first_name  } ${  item.last_name}`
          })
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    vSelect,
    HeadComponent,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? this.$route.query.filter : this.$route.query.filter : 'pending'
    this.$router.push({
      query: {
        page: this.currentPage,
        filter: this.filters
      }
    }).catch(() => {})
    this.loadData()
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.bg-confirm {
  background: #17814C !important;
}
.con-pagination-table{
  display: none;
}
.select-gov {
  .v-select {
    &.address {
      width: 100%;
      min-width: 150px;
      .vs__selected {
        width: 90px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    background: #fff;
    border-radius: 6px;
    // position: relative
    &.order-type {
      .vs__selected {
        color: #0A3266;
        white-space: nowrap;
      }
      .vs__dropdown-toggle {
        border: none;
      }
    }
    .vs__selected {
      color: #1C5BFE;
      padding: 0px;
    }
    .vs__actions {
      padding: 4px 2px 0px 3px;
      .vs__clear {
        margin-right: 0px;
      }
    }
    .vs__dropdown-toggle {
      border: 1px solid #C9C9C9;
      border-radius: 6px;
      height: 100%;
      min-width: 100px;
      .vs__selected-options {
        input.vs__search {
          position: absolute;
          inset: 0;
          z-index: 1;
        }
      }
    }
    .feather-chevron-down, .feather-x {
      fill: #E5E5E5;
      stroke: #E5E5E5;
    }
    ul.vs__dropdown-menu {
      z-index: 9999999999 !important;
      top: calc(100% - 25px);
    }
    ul {
      border: none;
      z-index: 2;
      margin-top: 0px;
      overflow-x: hidden;
      width: fit-content;
      li {
        padding: 3px 6px;
      }
    }
  }
}
</style>